import Axios from "@/plugins/Axios";
import store from "@/store";
import Disciplina from "@/Models/Disciplina";

export class DisciplinasService {
  async syncAll() {
    const response = await Axios().get("disciplinas");
    const disciplinas = response.data.map((disciplina) => new Disciplina(disciplina));
    store.commit("Disciplinas/setDisciplinas", disciplinas);
    return disciplinas;
  }

  async criarDisciplina(disciplina) {
    const response = await Axios().post("disciplinas", disciplina);
    const novaDisciplina = response.data;
    store.commit("Disciplinas/adicionarDisciplina", novaDisciplina);
    return novaDisciplina;
  }

  async atualizarDisciplina(disciplina) {
    const response = await Axios().put(`disciplinas/${disciplina.id}`, disciplina);
    const disciplinaAtualizada = response.data;
    store.commit("Disciplinas/atualizarDisciplina", disciplinaAtualizada);
    return disciplinaAtualizada;
  }

  async deletarDisciplina(disciplina) {
    const response = await Axios().delete(`disciplinas/${disciplina.id}`);
    const disciplinaDeletada = response.data;
    store.commit("Disciplinas/deletarDisciplina", disciplina.id);
    return disciplinaDeletada;
  }

  async recuperarDisciplina(disciplina) {
    return this.atualizarDisciplina({ ...disciplina, deleted_at: null });
  }

  async listarDisciplinasDosDiarios(matricula) {
    const response = await Axios().get(`disciplinas/${matricula}`);
    return response.data;
  }
}
export default new DisciplinasService();
