<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'turmas' })"
            :disabled="false"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Turma
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="turma">
        <v-card color="green lighten-1" class="mb-5" outlined>
          <v-card-title> Turma: {{ turma.descricao }}</v-card-title>
          <v-card-subtitle class="font-weight-medium">
            <div>Professor(a): {{ professor.nome || "- - -" }}</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-3">
      <template>
        <v-tabs v-model="tab_atual" background-color="primary" dark>
          <v-tab v-for="tab of tabs" :key="tabs.indexOf(tab)">{{ tab.descricao }}</v-tab>
        </v-tabs>
        <div class="text-right">
          <v-btn color="primary" class="ml-4 mt-4 mr-4 white--text" @click="gerarRelatorio">
            Lista da turma
            <v-icon right dark> mdi-file </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-simple-table v-if="matriculas" class="elevation-1" v-show="tab_atual == 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">N º</th>
                  <th class="text-left" width="300px">Aluno</th>
                  <th class="text-center" width="15%">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="matricula of matriculas"
                  :key="matricula.id"
                  :class="!matricula.aluno ? 'accent' : ''"
                >
                  <td width="20">{{ matriculas.indexOf(matricula) + 1 }}</td>
                  <td class="font-weight-medium text-left">
                    <div>
                      {{
                        matricula.aluno
                          ? matricula.aluno.nomecompleto
                          : `[ALUNO POSSIVELMENTE DELETADO] MATRICULA: ${matricula.id}`
                      }}
                    </div>
                    <div>
                      <small>
                        {{ matricula.aluno ? matricula.aluno.codigo_inep : "- - -" }}
                        /
                        {{ matricula.aluno ? matricula.aluno.codigo : "- - -" }}
                      </small>
                    </div>
                  </td>
                  <td class="text-center">
                    <v-tooltip bottom v-if="matricula.aluno">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="blue lighten-3"
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                            name: 'relatorios.lancarRelatorioBNCC',
                            params: { matricula_id: matricula.id },
                          }"
                        >
                          <v-icon small>fa fa-file-invoice</v-icon>
                        </v-btn>
                      </template>
                      <span>Lançar relatório da(e) {{ turma.curso.descricao }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="matricula.aluno">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-2"
                          color="red lighten-5"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => RelatorioBNCC(matricula.id)"
                        >
                          <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
                        </v-btn>
                      </template>
                      <span>Gerar relatório da(e) {{ turma.curso.descricao }}</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="matricula.aluno">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="blue lighten-3"
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                            name: 'relatorios.lancarRelatorioAnual',
                            params: { matricula_id: matricula.id },
                          }"
                        >
                          <v-icon small>fa fa-calendar-check</v-icon>
                        </v-btn>
                      </template>
                      <span>Lançar relatório anual</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="matricula.aluno">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-2"
                          color="red lighten-5"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => RelatorioAnual(matricula.id)"
                        >
                          <v-icon color="orange lighten-1" small>fa fa-calendar-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Gerar relatório anual</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table v-if="gestoes_de_aulas" class="elevation-1" v-show="tab_atual == 1">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">N º</th>
                  <th class="text-left" width="300px">Disciplina</th>
                  <th class="text-left" width="300px">Professor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="diario of gestoes_de_aulas" :key="gestoes_de_aulas.indexOf(diario)">
                  <td width="20">{{ gestoes_de_aulas.indexOf(diario) + 1 }}</td>
                  <td>{{ diario.disciplina.descricao }}</td>
                  <td>{{ diario.professor.nome }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </template>
    </v-card>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      turma: null,
      matriculas: null,
      gestoes_de_aulas: null,
      tab_atual: 0,
      tabs: [
        {
          id: 0,
          descricao: "Lista da Turma",
        },
        {
          id: 1,
          descricao: "Diários Relacionados",
        },
      ],
    };
  },
  mounted() {
    this.loadBasicData();
  },
  created() {
    this.loadBasicData();
  },
  methods: {
    async gerarRelatorio() {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.listarTurma(parseInt(this.$route.params.turmaId, 10));
      this.$loaderService.close();
    },
    async RelatorioBNCC(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.relatorioBNCC(parseInt(matricula_id, 10));
      this.$loaderService.close();
    },
    async RelatorioAnual(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.relatorioAnual(parseInt(matricula_id, 10));
      this.$loaderService.close();
    },
    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const payload = await this.$services.gestoesDeAulasService.visualizar(
          parseInt(this.$route.params.gestaoDeAulasId, 10)
        );
        this.turma = payload.turma;
        this.professor = payload.professor;
        this.matriculas = payload.turma.matriculas;

        const gestoesDaTurmaInfantil =
          await this.$services.gestoesDeAulasService.gestoesDaTurmaInfantil(
            parseInt(this.turma.id, 10)
          );
        this.gestoes_de_aulas = [...gestoesDaTurmaInfantil];

        // console.log(gestoesDaTurmaInfantil);
      } catch (error) {
        this.$handleError(error);
      }

      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
