<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'turmas' })"
            :disabled="criandoTurma"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Turma
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="criandoTurma">
        <v-row>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label>Código</e-label>
            <v-text-field v-model="form.codigo" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="8">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <e-label>Descrição</e-label>
              <v-text-field :error-messages="errors" v-model="form.descricao" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="1">
            <e-label> Notifiq</e-label>

            <v-switch v-model="switch1"></v-switch>
          </v-col>
          <v-col class="pt-0 pb-0" cols="5">
            <e-label>Tipo de mediação didático-pedagógica</e-label>
            <e-autocomplete
              :items="$constants.tiposDeMediacaoDidaticoPedagogica"
              :return-object="false"
              :item-value="(value) => value"
              v-model="form.tipos_de_mediacao_didatico_pedagogica"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="7">
            <e-label> Local de funcionamento diferenciado da turma</e-label>

            <e-autocomplete
              :items="$constants.localDeFuncionamentoDiferenciadoDaTurma"
              :return-object="false"
              :item-value="(value) => value"
              v-model="form.local_de_funcionamento_diferenciado_da_turma"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <template v-if="user && user.ano_id <= 3">
            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Horário Inicial</e-label>
              <!-- <e-time-picker v-model="form.horario_inicial" /> -->
              <v-text-field v-model="form.horario_inicial" dense solo />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Horário Final</e-label>
              <v-text-field v-model="form.horario_final" dense solo />
            </v-col>
          </template>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Dias da semana da turma</e-label>

            <e-autocomplete
              :items="$constants.diasDaSemana"
              :return-object="false"
              :item-value="(value) => value"
              v-model="form.dias_da_semana"
              label="Selecione uma opção"
              dense
              solo
              multiple
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Tipo de atendimento</e-label>

            <e-autocomplete
              :items="$constants.tiposDeAtendimento"
              :return-object="false"
              :item-value="(value) => value"
              v-model="form.tipo_de_atendimento"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Tipo de atividade complementar</e-label>

            <e-autocomplete
              :items="$constants.atividadesComplementaresList"
              :return-object="false"
              :item-value="(item) => item.codigo"
              :item-text="(item) => `${item.codigo} - ${item.descricao}`"
              v-model="form.tipo_de_atividade_complementar"
              label="Selecione uma opção"
              dense
              solo
              multiple
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Modalidade</e-label>

            <e-autocomplete
              :items="$constants.modalidades"
              :return-object="false"
              :item-value="(value) => value"
              v-model="form.modalidade"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <template v-if="user && user.ano_id <= 3">
            <v-col class="pt-0 pb-0" cols="4">
              <ValidationProvider name="Turno" rules="required" v-slot="{ errors }">
                <e-label>Turno</e-label>
                <e-autocomplete
                  :items="turnosList"
                  :loading="carregandoTurnos"
                  :item-text="(turno) => turno.descricao"
                  :return-object="false"
                  :item-value="(turno) => turno.id"
                  :error-messages="errors"
                  v-model="form.turno_id"
                  label="Selecione uma opção"
                  dense
                  solo
                />
              </ValidationProvider>
            </v-col>
          </template>
          <template v-else>
            <v-col class="pt-0 pb-0 mt-7" cols="4">
              <v-btn
                color="primary"
                block
                @click="
                  () => {
                    rotinas = true;
                  }
                "
                >Adicionar/Editar Rotinas</v-btn
              >
            </v-col>
          </template>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Série</e-label>
            <ValidationProvider name="Série" v-slot="{ errors }" rules="required">
              <e-autocomplete
                :items="seriesList.filter((serie) => serie.situacao)"
                :loading="carregandoSeries"
                :item-text="(serie) => serie.descricao"
                :return-object="false"
                :item-value="(serie) => serie.id"
                :error-messages="errors"
                v-model="form.serieIdList"
                label="Selecione uma opção"
                dense
                solo
                multiple
              />
            </ValidationProvider>
          </v-col>
          <!-- <template v-if="user && user.ano_id <= 4">
            <v-col class="pt-0 pb-0" cols="3">
              <e-label>Circuito de Notas</e-label>
              <ValidationProvider name="Sala" rules="" v-slot="{ errors }">
                <e-autocomplete
                  :items="circuitoDeNotasList.filter((circuito) => circuito)"
                  :loading="carregandoCircuito"
                  :item-text="(circuito) => circuito.descricao"
                  :return-object="false"
                  :item-value="(circuito) => circuito.id"
                  :error-messages="errors"
                  v-model="form.circuito_nota_id"
                  label="Selecione uma opção"
                  dense
                  solo
                />
              </ValidationProvider>
            </v-col>
          </template> -->
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="TiposDeEnsinos" rules="required" v-slot="{ errors }">
              <e-label>Tipo de Ensino</e-label>
              <e-autocomplete
                :items="tiposDeEnsinosList"
                :loading="carregandoTiposDeEnsinos"
                :item-text="(tipoDeEnsino) => tipoDeEnsino.descricao"
                :item-value="(tipoDeEnsino) => tipoDeEnsino.id"
                :error-messages="errors"
                @change="alterarSistemaBNCC"
                v-model="form.curso_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col
            class="pt-0 pb-0"
            cols="4"
            v-if="form.curso_id == 10 && form.serieIdList?.length > 1 && user.ano.id > 4"
          >
            <div class="d-flex align-center">
              <e-label>Sistema BNCC</e-label>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" @click="avisoBNCCDialog = true">
                    <v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Clique para mais informações</span>
              </v-tooltip>
            </div>

            <v-row dense class="pt-2">
              <v-chip class="ma-1" color="primary" outlined>Definido por série</v-chip>
            </v-row>

            <v-dialog v-model="avisoBNCCDialog" max-width="400">
              <v-card>
                <v-card-title class="headline">Informações sobre BNCC</v-card-title>
                <v-card-text>
                  A partir de 2025,em turmas MULTIETAPAS, o BNCC não estará mais vinculado às
                  turmas, e sim às séries da matrícula. <br />
                  Agora, está automaticamente desta forma: <br />

                  <strong>BNCC Creche:</strong>
                  <ul>
                    <li>Maternal 1</li>
                    <li>Maternal 2</li>
                    <li>Berçário 1</li>
                    <li>Berçário 2</li>
                  </ul>

                  <strong>BNCC Pré-escola:</strong>
                  <ul>
                    <li>Infantil 1</li>
                    <li>Infantil 2</li>
                  </ul>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" text @click="avisoBNCCDialog = false">Fechar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4" v-else-if="form.curso_id == 10">
            <ValidationProvider name="sistemaBNCC" rules="required" v-slot="{ errors }">
              <e-label>Sistema BNCC</e-label>
              <e-autocomplete
                :items="sistemaBNCCList.filter((sistema) => !sistema.parent_id)"
                :loading="carregandoSistemaBNCC"
                :item-text="(sistemaBNCC) => sistemaBNCC.descricao"
                :item-value="(sistemaBNCC) => sistemaBNCC.id"
                :error-messages="errors"
                v-model="form.sistema_bncc_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="criandoTurma"
              :loading="criandoTurma"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>

    <v-bottom-sheet v-model="rotinas" scrollable>
      <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
        <v-btn color="error" dark small @click="rotinas = !rotinas"> Fechar </v-btn>

        <h2 class="mt-4">Rotinas da turma</h2>

        <v-card>
          <v-card-text class="scrollable-content">
            <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
              <template>
                <thead style="background-color: #e0e0e0">
                  <tr>
                    <th class="text-left">SEG</th>
                    <th class="text-left">TER</th>
                    <th class="text-left">QUA</th>
                    <th class="text-left">QUI</th>
                    <th class="text-left">SEX</th>
                  </tr>
                </thead>
                <tbody class="text-left">
                  <tr>
                    <td>
                      <template>
                        <v-treeview
                          open-all
                          selectable
                          item-disabled="locked"
                          :items="itemsRotinaSeg"
                          v-model="selectionRotinasSeg"
                        ></v-treeview>
                      </template>
                    </td>
                    <td>
                      <template>
                        <v-treeview
                          open-all
                          selectable
                          item-disabled="locked"
                          :items="itemsRotinaTer"
                          v-model="selectionRotinasTer"
                        ></v-treeview>
                      </template>
                    </td>
                    <td>
                      <template>
                        <v-treeview
                          open-all
                          selectable
                          item-disabled="locked"
                          :items="itemsRotinaQua"
                          v-model="selectionRotinasQua"
                        ></v-treeview>
                      </template>
                    </td>
                    <td>
                      <template>
                        <v-treeview
                          open-all
                          selectable
                          item-disabled="locked"
                          :items="itemsRotinaQui"
                          v-model="selectionRotinasQui"
                        ></v-treeview>
                      </template>
                    </td>
                    <td>
                      <template>
                        <v-treeview
                          open-all
                          selectable
                          item-disabled="locked"
                          :items="itemsRotinaSex"
                          v-model="selectionRotinasSex"
                        ></v-treeview>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.getUserAuth();
    this.loadData();
  },
  watch: {
    switch1(newVal) {
      if (newVal) {
        this.form.notifiq = 1;
        return;
      }
      this.form.notifiq = 0;
    },
  },
  data() {
    return {
      rotinas: false,
      form: {},
      seriesList: [],
      turnosList: [],
      sistemaBNCCList: [],
      tiposDeEnsinosList: [],
      criandoTurma: false,
      carregandoSeries: false,
      carregandoTurnos: false,
      carregandoTiposDeEnsinos: false,
      carregandoSistemaBNCC: false,
      switch1: false,
      circuitoDeNotasList: [],
      carregandoCircuito: false,
      user: null,
      itemsRotinaSeg: this.$constants.rotinasTurma,
      selectionRotinasSeg: [],
      itemsRotinaTer: this.$constants.rotinasTurma,
      selectionRotinasTer: [],
      itemsRotinaQua: this.$constants.rotinasTurma,
      selectionRotinasQua: [],
      itemsRotinaQui: this.$constants.rotinasTurma,
      selectionRotinasQui: [],
      itemsRotinaSex: this.$constants.rotinasTurma,
      selectionRotinasSex: [],
      avisoBNCCDialog: false,
    };
  },
  methods: {
    async getUserAuth() {
      const response = await this.$services.authService.getCurrentUser();
      // console.log("user::", response);
      this.user = response;
    },

    alterarSistemaBNCC() {
      if (this.form.curso !== 10) this.form.sistema_bncc_id = null;
    },
    async submitForm() {
      try {
        if (this.user.ano_id >= 4) {
          // salvar rotinas
          this.form.rotina = [
            {
              seg: this.selectionRotinasSeg,
              ter: this.selectionRotinasTer,
              qua: this.selectionRotinasQua,
              qui: this.selectionRotinasQui,
              sex: this.selectionRotinasSex,
            },
          ];
          this.form.rotina = JSON.stringify(this.form.rotina);
        }

        if (this.editing) {
          await this.$services.turmasService.atualizarTurma(this.form);

          this.$toast.success("Turma Atualizada com sucesso");
        } else {
          await this.$services.turmasService.criarTurma(this.form);
          this.$toast.success("Turma criada com sucesso");
        }
        this.$router.push({ name: "turmas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.criandoTurma = false;
    },
    async loadData() {
      this.getUserAuth();
      this.$loaderService.open("Carregando dados");
      try {
        if (this.editing) {
          const { turma_id } = this.$route.params;
          const payload = await this.$services.turmasService.visualizarTurma(turma_id);
          const series = await payload.turma.series.map((serie) => (serie.id ? serie.id : serie));
          this.form = { ...payload.turma, serieIdList: series };
          this.switch1 = parseInt(payload.turma.notifiq, 10) === 1;

          if (this.user.ano_id >= 4) {
            // carregar rotinas
            const rotinas = JSON.parse(payload.turma.rotina);
            if (rotinas) {
              this.selectionRotinasSeg = rotinas[0].seg;
              this.selectionRotinasTer = rotinas[0].ter;
              this.selectionRotinasQua = rotinas[0].qua;
              this.selectionRotinasQui = rotinas[0].qui;
              this.selectionRotinasSex = rotinas[0].sex;
            }
          }
        }
        this.tiposDeEnsinosList = await this.$services.tiposDeEnsinosService.syncAll();
        this.turnosList = await this.$services.turnosService.syncAll();
        this.seriesList = await this.$services.seriesService.syncAll();
        this.circuitoDeNotasList = await this.$services.circuitoNotasService.syncAll();
        const response = await this.$services.sistemaBNCCService.listarTodos();
        this.sistemaBNCCList = response.sistema;

        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style>
.scrollable-content {
  max-height: 500px; /* Defina a altura máxima para ativar a rolagem */
  overflow-y: auto; /* Adicione uma barra de rolagem vertical conforme necessário */
}
</style>
