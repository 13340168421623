<template>
  <div>
    <div v-for="(item, index) in filteredSistemaBNCC" :key="item.id" class="grafico-item">
      <h4 class="text-center">
        {{
          `9.${index + 1} - OBSERVAÇÃO DO CAMPO DE EXPERIÊNCIAS ${item.descricao} - ${getSigla(
            item.descricao
          )}`
        }}
      </h4>
      <canvas :ref="(el) => setCanvasRef(el, index, item.id)"></canvas>
    </div>
  </div>
</template>

<script>
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

export default {
  props: {
    filteredSistemaBNCC: {
      type: Array,
      required: true,
    },
    notas: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      canvasRefs: {},
    };
  },
  mounted() {
    this.filteredSistemaBNCC.forEach((parent, index) => {
      this.renderChart(parent.children, index, parent.id);
    });
  },
  methods: {
    setCanvasRef(el, index, parentId) {
      if (el) {
        this.$set(this.canvasRefs, `${index}-${parentId}`, el);
      }
    },
    renderChart(children, index, parentId) {
      const canvas = this.canvasRefs[`${index}-${parentId}`];

      if (!canvas) return;

      const ctx = canvas.getContext("2d");

      const labels = ["1º PERÍODO", "2º PERÍODO", "3º PERÍODO", "4º PERÍODO"];
      const niveisDesempenho = ["ND", "ED", "D", "R", "B", "MB"];

      const colors = [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(199, 199, 199, 1)",
        "rgba(83, 102, 255, 1)",
        "rgba(47, 79, 79, 1)",
      ];

      const datasets = children.map((child, childIndex) => {
        const color = colors[childIndex % colors.length];

        // Função para formatar a descrição
        const formatLabel = (text, maxLength = 50) => {
          if (text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`; // Corta e adiciona "..."
          }
          return text.replace(/(.{30})/g, "$1\n"); // Quebra a cada 30 caracteres
        };

        return {
          label: formatLabel(child.descricao), // Aplica a formatação
          fullLabel: child.descricao, // Armazena a descrição completa
          data: [
            this.mapNota(this.notas[child.id]?.nota_p1),
            this.mapNota(this.notas[child.id]?.nota_p2),
            this.mapNota(this.notas[child.id]?.nota_p3),
            this.mapNota(this.notas[child.id]?.nota_p4),
          ],
          borderColor: color,
          backgroundColor: "rgba(0, 0, 0, 0)",
          pointStyle: "circle",
          pointRadius: 6,
          borderWidth: 2,
        };
      });

      new Chart(ctx, {
        type: "line",
        data: {
          labels,
          datasets,
        },
        options: {
          responsive: true, // Torna o gráfico responsivo
          maintainAspectRatio: true, // Mantém a proporção correta
          plugins: {
            title: {
              display: true,
              text: "Evolução das Habilidades",
              font: {
                size: 21,
                weight: "bold",
              },
              color: "#222",
            },
            legend: {
              labels: {
                font: {
                  size: 21,
                  weight: "bold",
                },
                color: "#222",
              },
              onHover: (event, legendItem) => {
                const tooltip = document.createElement("div");
                tooltip.className = "chart-tooltip";
                tooltip.innerHTML = legendItem.text;
                document.body.appendChild(tooltip);

                event.native.target.addEventListener("mousemove", (e) => {
                  tooltip.style.left = `${e.pageX + 10}px`;
                  tooltip.style.top = `${e.pageY + 10}px`;
                });

                event.native.target.addEventListener("mouseleave", () => {
                  document.body.removeChild(tooltip);
                });
              },
            },
            tooltip: {
              callbacks: {
                label(context) {
                  return context.dataset.fullLabel;
                },
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              suggestedMax: 5,
              ticks: {
                stepSize: 1,
                padding: 10,
                font: {
                  size: 21,
                  weight: "bold",
                },
                color: "#222",
                callback(value) {
                  return niveisDesempenho[value];
                },
              },
              title: {
                display: true,
                text: "Níveis de Desempenho",
                font: {
                  size: 21,
                  weight: "bold",
                },
                color: "#222",
              },
            },
            x: {
              ticks: {
                font: {
                  size: 21,
                  weight: "bold",
                },
                color: "#222",
              },
              title: {
                display: true,
                text: "Períodos",
                font: {
                  size: 21,
                  weight: "bold",
                },
                color: "#222",
              },
            },
          },
        },
      });
    },

    mapNota(nota) {
      const mapa = { ND: 0, ED: 1, D: 2, R: 3, B: 4, MB: 5 };
      return mapa[nota] ?? null;
    },

    getSigla(descricao) {
      const siglas = {
        "CAMPO DE EXPERIÊNCIAS “O EU, O OUTRO E O NÓS”": "EON",
        "CAMPO DE EXPERIÊNCIAS “CORPO, GESTOS E MOVIMENTOS”": "CGM",
        "CAMPO DE EXPERIÊNCIAS “TRAÇOS, SONS, CORES E FORMAS”": "TSCF",
        "CAMPO DE EXPERIÊNCIAS “ESCUTA, FALA, PENSAMENTO E IMAGINAÇÃO”": "EFPI",
        "A CAMPO DE EXPERIÊNCIAS “ESPAÇOS, TEMPOS, QUANTIDADES, RELAÇÕES E TRANSFORMAÇÕES”":
          "ETQRT",
      };

      return siglas[descricao] || "";
    },

    getChartImage(index, parentId) {
      const canvas = this.canvasRefs[`${index}-${parentId}`];
      if (!canvas) return null;

      return canvas.toDataURL("image/png", 1.0); // Qualidade máxima da imagem
    },
  },
};
</script>

<style scoped>
.grafico-item {
  margin-bottom: 30px;
  text-align: center;
}

canvas {
  max-width: 100%;
  height: auto;
}

.chart-tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  pointer-events: none;
  z-index: 1000;
}
</style>
