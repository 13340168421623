var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-template',[_c('v-row',[_c('v-col',[_c('h1',[_c('v-btn',{attrs:{"color":"primary","x-small":"","text":"","disabled":_vm.submittingForm},on:{"click":() => _vm.$router.push({ name: 'matriculas' })}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fa fa-angle-left ")])],1),_vm._v(" "+_vm._s(_vm.editing ? "Editando" : "Criando")+" Matricula ")],1)])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"disabled":_vm.submittingForm},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_vm._l((_vm.form.matriculas),function(matricula){return _c('v-row',{key:_vm.form.matriculas.indexOf(matricula)},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"4"}},[_c('e-label',[_vm._v("Aluno")]),_c('e-autocomplete',{attrs:{"items":_vm.alunosList,"return-object":false,"disabled":_vm.editing,"item-value":(aluno) => aluno.id,"item-text":(aluno) => aluno.nomecompleto,"loading":_vm.carregandoAlunos,"readonly":_vm.editing,"label":"Selecione uma opção","dense":"","solo":""},model:{value:(matricula.aluno_id),callback:function ($$v) {_vm.$set(matricula, "aluno_id", $$v)},expression:"matricula.aluno_id"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"4"}},[_c('e-label',[_vm._v("Turma")]),_c('e-autocomplete',{attrs:{"items":_vm.turmasList,"return-object":true,"disabled":_vm.editing,"item-text":(turma) =>
                turma.is_atividade_complementar
                  ? `${turma.descricao} - ${turma.franquia?.descricao}`
                  : turma.descricao,"loading":_vm.carregandoTurmas,"label":"Selecione uma opção","dense":"","solo":""},on:{"change":(turma) => _vm.changeTurma(matricula, turma)},model:{value:(matricula.turma),callback:function ($$v) {_vm.$set(matricula, "turma", $$v)},expression:"matricula.turma"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"3"}},[_c('e-label',[_vm._v("Ano")]),_c('e-autocomplete',{attrs:{"items":matricula.turma ? matricula.turma.series : [],"return-object":false,"item-value":(serie) => serie.id,"item-text":(serie) => serie.descricao,"disabled":!matricula.turma,"label":"Selecione uma opção","dense":"","solo":""},model:{value:(matricula.serie_id),callback:function ($$v) {_vm.$set(matricula, "serie_id", $$v)},expression:"matricula.serie_id"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"1"}},[(_vm.form.matriculas.length > 1)?_c('v-btn',{staticClass:"mt-8",attrs:{"color":"error","small":""},on:{"click":() =>
                (_vm.form.matriculas = _vm.form.matriculas.filter(
                  (matriculaObj) => matriculaObj !== matricula
                ))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1):_vm._e()],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.submittingForm,"loading":_vm.submittingForm,"type":"submit"}},[_vm._v("Enviar Dados")]),(!_vm.editing)?_c('v-btn',{staticClass:"ml-3",attrs:{"color":"secondary"},on:{"click":_vm.adicionarMatricula}},[_vm._v(" Adicionar ")]):_vm._e()],1)])],1)],2)]}}])}),(_vm.turmasAtividadeComplementar)?_c('div',{staticClass:"my-6"},[_c('v-alert',{attrs:{"border":"left","dense":"","type":"info"}},[_c('strong',[_vm._v(" ATENÇÃO - ")]),_vm._v(" As matrículas em turmas de "),_c('strong',[_vm._v(" atividade complementar")]),_vm._v(" são exibidas apenas na listagem de matrículas da franquia onde a turma foi cadastrada. ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }