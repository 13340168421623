<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.back()"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Plano Mensal Especial
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" v-if="infor">
        <v-card color="primary">
          <v-col cols="12">
            <v-chip class="white--text mb-3" color="blue" style="margin: 3px">
              <v-icon left> mdi-account-tie </v-icon>
              Nome do(a) profissional de apoio:
              {{ professor ? professor.nome : "[ *Sem profissional de apoio ]" }}
            </v-chip>
            <br />

            <v-chip class="white--text mb-3" color="blue" style="margin: 3px">
              <v-icon left> mdi-account-circle </v-icon>
              Aluno:
              {{ infor.aluno ? infor.aluno.nomecompleto : "[- - -]" }}
            </v-chip>
            <br />

            <v-chip class="white--text mb-3" color="blue" style="margin: 3px">
              <!-- <v-icon left>mdi-school</v-icon> -->
              <v-icon left>mdi-school</v-icon>
              Turma:
              {{ infor.turma ? infor.turma.descricao : "[- - -]" }}
            </v-chip>
            <v-chip class="white--text mb-3" color="blue" style="margin: 3px">
              <v-icon left>mdi-counter</v-icon>
              Serie:
              {{ infor.serie ? infor.serie.descricao : "[- - -]" }}
            </v-chip>
            <br />
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row>
          <!-- Ciclo do planejamento -->
          <v-col cols="12" lg="12">
            <v-card class="pa-md-4 mt-2">
              <label for="">CICLO DO PLANEJAMENTO</label>
              <v-select
                class="mt-4"
                :items="meses"
                item-text="descricao"
                item-value="id"
                v-model="mes_selecionado"
                return-object
                label="Selecione um mês"
                outlined
                dense
              >
              </v-select>
            </v-card>

            <v-card class="pa-md-4 mt-5">
              <label for="">PERÍODO LETIVO</label>
              <v-select
                class="mt-4"
                :items="periodos"
                item-text="descricao"
                item-value="id"
                v-model="periodo_selecionado"
                return-object
                label="Selecione"
                outlined
                dense
              >
              </v-select>
            </v-card>

            <v-card class="pa-md-4 mt-5">
              <label for="">COMPONENTE CURRICULAR/DISCIPLINA/CAMPOS DE EXPERIÊNCIAS</label>
              <v-select
                class="mt-4"
                :items="disciplinas"
                item-text="descricao"
                item-value="id"
                v-model="disciplina_selecionada"
                return-object
                label="Selecione uma disciplina"
                outlined
                dense
              >
              </v-select>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for=""> CONTEÚDO | ASSUNTO | OBJETOS DE CONHECIMENTO</label>
                <v-textarea
                  rows="3"
                  autofocus
                  v-model="objetos_do_conhecimento"
                  placeholder="Digite Aqui"
                ></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">QUAL A DEFICIÊNCIA DO(A) ESTUDANTE?</label>
                <v-textarea
                  rows="3"
                  v-model="deficiencia_do_estudante"
                  placeholder="Digite Aqui"
                ></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mb-3 mt-5" color="blue" dark v-bind="attrs" v-on="on">
                  INSIRA O CÓDIGO DCTMA
                </v-btn>
              </template>

              <v-card>
                <v-toolbar dark color="blue">
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>PESQUISA</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-col>
                  <v-row>
                    <v-col cols="12">
                      <v-sheet color="white" elevation="1">
                        <div class="py-2 px-4" color="white">
                          <v-text-field
                            v-model="search"
                            autofocus
                            v-on:keyup="() => procurarHabilidade()"
                            label="DIGITE O CODIGO DCTMA"
                          ></v-text-field>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-sheet color="white" elevation="1" rounded>
                        <v-lazy>
                          <div class="text-start py-2 px-4 text-button blue white--text">
                            HABILIDADES ENCONTRADAS
                            <v-icon dark right> mdi-magnify </v-icon>
                          </div>
                        </v-lazy>
                        <v-divider class="theme--light"></v-divider>
                        <div class="py-2 px-4">
                          <v-list rounded>
                            <v-list-item-group>
                              <v-list-item
                                class="grey lighten-3 mb-3 pa-md-4"
                                v-for="m in habilidadesPesquisa"
                                :key="m.id"
                              >
                                [ {{ m.codigo }} ] {{ m.descricao }}
                                <v-btn
                                  @click="() => selecionarHabilidade(m)"
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="blue"
                                >
                                  <v-icon dark> mdi-plus </v-icon>
                                </v-btn>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </v-sheet>
                    </v-col>
                    <v-col cols="6">
                      <v-sheet color="white" elevation="1">
                        <v-lazy>
                          <div class="text-start py-2 px-4 text-button blue white--text">
                            Habilidades selecionados
                            <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                          </div>
                        </v-lazy>
                        <v-divider class="theme--light"></v-divider>
                        <div class="py-2 px-4">
                          <v-list rounded>
                            <v-list-item-group>
                              <v-list-item
                                class="grey lighten-3 mb-3 pa-md-4"
                                v-for="(m, index) in habilidades_exploradas"
                                :key="m.id"
                              >
                                <v-btn
                                  @click="() => deletarHabilidade(index)"
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="red"
                                >
                                  <v-icon dark> mdi-delete </v-icon>
                                </v-btn>
                                {{ m.codigo }} - {{ m.descricao }}
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
            </v-dialog>

            <v-card class="pa-md-4">
              <div>
                <label for=""> OBJETIVOS DE APRENDIZAGEM E DESENVOLVIMENTO </label>
                <v-list rounded>
                  <v-list-item-group color="primary">
                    <p v-if="habilidades_exploradas.length === 0">(Nenhum codigo adicionado)</p>
                    <v-list-item
                      class="grey lighten-3 mb-3 pa-md-4"
                      v-for="(m, index) in habilidades_exploradas"
                      :key="index"
                    >
                      <v-btn
                        @click="() => deletarHabilidade(index)"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                      >
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                      {{ m.codigo }} - {{ m.descricao }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for=""
                  >Capacidades/ Interesses do(a) estudante, dentro do contexto do assunto
                  escolhido</label
                >
                <v-textarea
                  rows="3"
                  v-model="capacidades_interesses_do_estudante"
                  placeholder="Digite Aqui"
                ></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">METODOLOGIAS</label>
                <v-autocomplete
                  v-model="metodologias_selecionadas"
                  :items="metodologias"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  multiple
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
                <v-textarea
                  v-model="form.metodologia_outros"
                  placeholder="Digite aqui"
                  rows="3"
                  label="Se precisar dê mais detalhes de sua metodologia (NÃO OBRIGATÓRIO)"
                  no-resize
                  auto-grow
                ></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">HABILIDADE(S) COGNITIVAS(S)</label>
                <v-autocomplete
                  v-model="habilidades_cognitivas"
                  :items="habilidades_especiais"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  multiple
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
                <v-textarea
                  v-model="form.habilidades_cognitivas_outros"
                  placeholder="Digite aqui"
                  rows="3"
                  label="Se precisar dê mais detalhes de sua metodologia (NÃO OBRIGATÓRIO)"
                  no-resize
                  auto-grow
                ></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">RECURSOS DE ENSINO</label>
                <v-autocomplete
                  v-model="recursos_de_ensino_selecionados"
                  :items="recursos_especiais"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  multiple
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">COMPETÊNCIAS GERAIS</label>
                <v-autocomplete
                  v-model="competencias_gerais"
                  :items="competencias_gerais_especiais"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  multiple
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">AVALIAÇÃO</label>
                <v-autocomplete
                  v-model="avaliacoes_selecionadas"
                  :items="avaliacoes"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  multiple
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for=""> BIBLIOGRAFIA </label>
                <v-textarea rows="3" v-model="bibliografia" placeholder="Digite aqui"></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">TIRA-DÚVIDAS ONLINE</label>
                <v-autocomplete
                  v-model="tira_duvidas_online_selecionadas"
                  :items="tira_duvidas"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  multiple
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">OUTRAS INFORMAÇÕES COMPLEMENTARES</label>
                <v-textarea
                  rows="3"
                  v-model="outras_informacoes_complementares"
                  placeholder="Digite Aqui"
                ></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for=""
                  >METAS E PRAZOS. EM QUANTO TEMPO PRETENDE DESENVOLVER AS HABILIDADES OU
                  COMPETENCIAS APONTADAS</label
                >
                <v-autocomplete
                  v-model="metas_e_prazos_selecionados"
                  :items="metas_e_prazos"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
            >
              Enviar Dados
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
    <v-alert
      v-show="formRequest"
      class="my-2 animate__animated animate__headShake"
      dense
      border="left"
      type="warning"
    >
      Preencha todo o questionário
    </v-alert>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  watch: {
    form() {
      this.formRequest = false;
    },
    mes_selecionado() {
      this.selectPlano();
    },
    periodo_selecionado() {
      this.selectPlano();
    },
    disciplina_selecionada(newVal, oldVal) {
      if (newVal.descricao !== oldVal.descricao) {
        this.selectPlano();
      }
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      disciplina: {},
      form: {
        mes_id: 1,
        periodo_id: 1,
      },
      infor: {},
      plano: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      disciplina_selecionada: {
        id: null,
      },
      disciplinas: [],
      professor: "",
      metas_e_prazos: [],
      metas_e_prazos_selecionados: [],
      search: "",
      habilidadesPesquisa: [],
      meses: [],
      periodos: [],
      habilidades_especiais: [],
      mes_selecionado: {
        id: 1,
        descricao: "Janeiro",
      },
      periodo_selecionado: {
        id: 1,
        descricao: "Periodo 1",
      },
      recursos_especiais: [],
      avaliacoes: [],
      tira_duvidas: [],
      competencias_gerais_especiais: [],
      habilidades_exploradas: [],
      deficiencia_do_estudante: "",
      objetos_do_conhecimento: "",
      capacidades_interesses_do_estudante: "",
      metodologias_selecionadas: [],
      metodologias: [],
      habilidades_cognitivas: [],
      recursos_de_ensino_selecionados: [],
      competencias_gerais: [],
      avaliacoes_selecionadas: [],
      tira_duvidas_online_selecionadas: [],
      bibliografia: "",
      nome_do_profissinal_de_apoio: "",
      outras_informacoes_complementares: "",
      dialog: false,
      menu: false,
      modal: false,
      submittingForm: false,
      formRequest: false,
      loadForm: false,
      progress: 90,
      tab_atual: 0,
      gestao: {},
    };
  },
  methods: {
    setForm() {},
    async selectPlano() {
      this.loadForm = true;
      this.$loaderService.open("Carregando dados...");
      try {
        const { matriculaId } = this.$route.params;
        // const { disciplinaId } = this.$route.params;
        const { id } = this.mes_selecionado;
        const periodo = this.periodo_selecionado.id;
        const data = await this.$services.planoMensalEspecialService.planoMensalEspecial(
          matriculaId,
          periodo,
          id,
          this.disciplina_selecionada.id
        );

        if (!data.plano) {
          this.objetos_do_conhecimento = "";
          this.deficiencia_do_estudante = "";
          this.bibliografia = "";
          this.outras_informacoes_complementares = "";
          this.nome_do_profissinal_de_apoio = "";
          this.capacidades_interesses_do_estudante = "";
          this.habilidades_exploradas = [];
          this.habilidades_cognitivas = [];
          this.metodologias_selecionadas = [];
          this.recursos_de_ensino_selecionados = [];
          this.competencias_gerais = [];
          this.avaliacoes_selecionadas = [];
          this.tira_duvidas_online_selecionadas = [];
          this.metas_e_prazos_selecionados = null;
          this.form = {
            periodo_id: periodo,
            mes_id: id,
          };
          this.$loaderService.close();

          return;
        }
        this.form = data.plano;
        this.habilidades_exploradas = data.plano.habilidades_exploradas;
        this.objetos_do_conhecimento = data.plano.objetos_do_conhecimento;
        this.deficiencia_do_estudante = data.plano.deficiencia_do_estudante;
        this.bibliografia = data.plano.bibliografia;
        this.outras_informacoes_complementares = data.plano.outras_informacoes_complementares;
        this.nome_do_profissinal_de_apoio = data.plano.nome_do_profissinal_de_apoio;
        this.capacidades_interesses_do_estudante = data.plano.capacidades_interesses_do_estudante;
        this.metas_e_prazos_selecionados = data.metas_e_prazos[0].id;
        this.habilidades_exploradas = data.habilidades;
        this.habilidades_cognitivas = data.habilidades_cognitivas;
        this.metodologias_selecionadas = data.metodologias_selecionadas;
        this.recursos_de_ensino_selecionados = data.recursos_de_ensino_selecionados;
        this.competencias_gerais = data.competencias_gerais_especiais;
        this.avaliacoes_selecionadas = data.avaliacoes_selecionadas;
        this.tira_duvidas_online_selecionadas = data.tira_duvidas_online_selecionadas;
        this.professor = data.professor;
        this.disciplina_selecionada = data.disciplina;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      this.formRequest = false;
      try {
        const { matriculaId } = this.$route.params;
        this.form.matricula_id = matriculaId;
        this.form.periodo_selecionado = this.periodo_selecionado.id;
        this.form.mes_selecionado = this.mes_selecionado.id;
        this.form.deficiencia_do_estudante = this.deficiencia_do_estudante;
        this.form.objetos_do_conhecimento = this.objetos_do_conhecimento;
        this.form.capacidades_interesses_do_estudante = this.capacidades_interesses_do_estudante;
        this.form.professor_id = this.professor.id;
        this.form.turma_id = this.infor.turma.id;
        this.form.disciplina_id = this.disciplina_selecionada.id
          ? this.disciplina_selecionada.id
          : this.disciplina_selecionada;
        this.form.metas_e_prazos = Array.isArray(this.metas_e_prazos_selecionados)
          ? this.metas_e_prazos_selecionados
          : [this.metas_e_prazos_selecionados];

        this.form.habilidades_exploradas = this.habilidades_exploradas.map((m) => {
          if (!m.id) {
            return m;
          }
          return m.id;
        });
        this.form.metodologias_selecionadas = this.metodologias_selecionadas.map((m) => {
          if (!m.id) {
            return m;
          }
          return m.id;
        });
        this.form.habilidades_cognitivas = this.habilidades_cognitivas.map((m) => {
          if (!m.id) {
            return m;
          }
          return m.id;
        });
        this.form.recursos_de_ensino_selecionados = this.recursos_de_ensino_selecionados.map(
          (m) => {
            if (!m.id) {
              return m;
            }
            return m.id;
          }
        );
        this.form.competencias_gerais = this.competencias_gerais.map((m) => {
          if (!m.id) {
            return m;
          }
          return m.id;
        });
        this.form.avaliacoes_selecionadas = this.avaliacoes_selecionadas.map((m) => {
          if (!m.id) {
            return m;
          }
          return m.id;
        });
        this.form.tira_duvidas_online_selecionadas = this.tira_duvidas_online_selecionadas.map(
          (m) => {
            if (!m.id) {
              return m;
            }
            return m.id;
          }
        );
        this.form.bibliografia = this.bibliografia;
        this.form.nome_do_profissinal_de_apoio = this.nome_do_profissinal_de_apoio;
        this.form.outras_informacoes_complementares = this.outras_informacoes_complementares;
        await this.$services.planoMensalEspecialService.createOrUpdate(this.form);
        this.$toast.success("Plano mensal salvo com sucesso!");
        // this.$router.back();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadData() {
      const { matriculaId } = this.$route.params;
      const { disciplinaId } = this.$route.params;
      const { mes_id } = this.form;
      const { periodo_id } = this.form;

      try {
        this.$loaderService.open("Carregando dados...");

        const data = await this.$services.planoMensalEspecialService.visualizarParaPlanoEspecial(
          matriculaId,
          mes_id,
          periodo_id,
          disciplinaId
        );

        const response = await this.$services.disciplinasService.listarDisciplinasDosDiarios(
          matriculaId
        );
        this.disciplinas = response.disciplinas;
        this.gestao = response.gestao;
        this.professor = this.gestao.professor;

        this.infor = data.matricula;
        this.disciplina_selecionada = data.disciplina;
        this.meses = data.meses;
        this.periodos = data.periodos;
        this.metodologias = data.metodologias;
        this.habilidades_especiais = data.habilidades_especiais;
        this.recursos_especiais = data.recursos_de_ensino_especial;
        this.competencias_gerais_especiais = data.competencias_gerais_especiais;
        this.avaliacoes = data.avaliacoes;
        this.tira_duvidas = data.tira_duvidas;
        this.metas_e_prazos = data.metas_e_prazos;

        if (data.plano) {
          const response = await this.$services.planoMensalEspecialService.planoMensalEspecial(
            parseInt(matriculaId, 10),
            parseInt(periodo_id, 10),
            parseInt(mes_id, 10),
            parseInt(disciplinaId, 10)
          );

          this.form = response.plano;
          this.objetos_do_conhecimento = response.plano.objetos_do_conhecimento;
          this.deficiencia_do_estudante = response.plano.deficiencia_do_estudante;
          this.bibliografia = response.plano.bibliografia;
          this.outras_informacoes_complementares = response.plano.outras_informacoes_complementares;
          this.nome_do_profissinal_de_apoio = response.plano.nome_do_profissinal_de_apoio;
          this.capacidades_interesses_do_estudante =
            response.plano.capacidades_interesses_do_estudante;
          this.professor = response.professor;

          this.metas_e_prazos_selecionados = response.metas_e_prazos[0].id;
          this.habilidades_exploradas = response.habilidades;
          this.habilidades_cognitivas = response.habilidades_cognitivas;
          this.metodologias_selecionadas = response.metodologias_selecionadas;
          this.recursos_de_ensino_selecionados = response.recursos_de_ensino_selecionados;
          this.competencias_gerais = response.competencias_gerais_especiais;
          this.avaliacoes_selecionadas = response.avaliacoes_selecionadas;
          this.tira_duvidas_online_selecionadas = response.tira_duvidas_online_selecionadas;
          this.disciplina_selecionada = response.disciplina;
        }

        if (!data.plano) {
          this.disciplina_selecionada = data.disciplina;
        }

        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async selecionarHabilidade(m) {
      const habilidade = this.habilidades_exploradas.find((h) => h.id === m.id);
      if (habilidade) return;
      this.habilidades_exploradas.push(m);
    },
    async deletarHabilidade(index) {
      this.habilidades_exploradas.splice(index, 1);
    },
    async procurarHabilidade() {
      const codigo_disciplina = this.infor.turma.curso.id;
      const dados = await this.$services.habilidadeService.procurarHabilidade(
        this.search,
        codigo_disciplina
      );
      this.habilidadesPesquisa = dados.data.data;
    },
  },
};
</script>

<style></style>
