<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Gestão de Relatório Anual
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="turma && aluno">
          <v-card-title>Relatório</v-card-title>
          <v-card-text>
            <h4>Turma: {{ turma.descricao }}</h4>
            <h4>Aluno: {{ aluno.nomecompleto }}</h4>
            <h4>QUANTIDADE DE PRESENÇAS DO(A) ESTUDANTE, NESTE ANO LETIVO: {{ presencasCount }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div v-for="diario in gestao_de_aulas" :key="diario.id">
              Professor(a): {{ diario.professor.nome }} | Disciplina:
              {{ diario?.disciplina?.descricao }}
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-col>
    </v-row>
    <form @submit.prevent="createOrUpdate">
      <v-row>
        <v-col cols="12">
          <v-card class="pa-md-4">
            <div>
              <label> 1. Introdução</label>
              <v-textarea
                rows="3"
                autofocus
                v-model="form.introducao"
                placeholder="Descreva brevemente o objetivo do relatório, destacando a importância das ações para o registro do desenvolvimento
integral das crianças da Educação Infantil. Apresente a metodologia e os enfoques pedagógicos adotados."
              ></v-textarea>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="pa-md-4">
            <div>
              <label> 2. Objetivos </label>
              <v-textarea
                rows="3"
                autofocus
                v-model="form.objetivos"
                placeholder="Liste os principais objetivos do planejamento para o período. Por exemplo:
Promover o desenvolvimento cognitivo, motor e emocional; Estimular a socialização e a autonomia;
Incentivar a expressão verbal e corporal; Fortalecer o vínculo com os responsáveis e a comunidade."
              ></v-textarea>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="pa-md-4">
            <!-- Título com alternância -->
            <div
              @click="toggleCampos"
              style="
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <label>3. Ações Realizadas</label>
              <v-icon>{{ exibirCampos ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            </div>

            <!-- Opções adicionais -->
            <div v-if="exibirCampos" class="mt-4">
              <v-card class="pa-md-4 mb-4">
                <h4>a) Desenvolvimento Motor</h4>
                <v-textarea
                  rows="3"
                  v-model="form.desenvolvimento_motor"
                  placeholder="Exemplo de Atividades: Caminhadas, brincadeiras com bola, circuitos motores, etc.
Exemplo de Objetivo: Melhorar o controle motor, equilíbrio e coordenação."
                ></v-textarea>
              </v-card>

              <v-card class="pa-md-4 mb-4">
                <h4>b) Desenvolvimento Cognitivo</h4>
                <v-textarea
                  rows="3"
                  v-model="form.desenvolvimento_cognitivo"
                  placeholder="Exemplo de Atividades: Jogos de encaixe, identificação de cores e formas, atividades de
exploração sensorial.
Exemplo de Objetivo: Estimular a curiosidade e a capacidade de resolver problemas."
                ></v-textarea>
              </v-card>

              <v-card class="pa-md-4 mb-4">
                <h4>c) Desenvolvimento Social e Emocional</h4>
                <v-textarea
                  rows="3"
                  v-model="form.desenvolvimento_social"
                  placeholder="Exemplo de Atividades: Rodas de conversa, brincadeiras em grupo, atividades de reconhecimento
das emoções.
Exemplo de Objetivo: Promover a socialização, empatia e autonomia."
                ></v-textarea>
              </v-card>

              <v-card class="pa-md-4 mb-4">
                <h4>d) Linguagem e Comunicação</h4>
                <v-textarea
                  rows="3"
                  v-model="form.linguagem_comunicacao"
                  placeholder="Exemplo de Atividades: Leitura de histórias, cantigas, atividades de expressão verbal.
Exemplo de Objetivo: Incentivar a comunicação verbal e não verbal, além de enriquecer o vocabulário."
                ></v-textarea>
              </v-card>

              <v-card class="pa-md-4">
                <h4>e) Atividades Artísticas</h4>
                <v-textarea
                  rows="3"
                  v-model="form.atividades_artisticas"
                  placeholder="Exemplo de Atividades: Pintura, colagem, modelagem com massinha.
Exemplo de Objetivo: Estimular a criatividade, a coordenação motora fina e a expressão artística."
                ></v-textarea>
              </v-card>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="pa-md-4">
            <div>
              <label> 4. Avaliação dos Resultados </label>
              <v-textarea
                rows="3"
                autofocus
                v-model="form.avaliacao_resultados"
                placeholder="Faça uma análise geral dos avanços observados nas crianças, considerando os objetivos propostos. Inclua:
Aspectos do desenvolvimento cognitivo, motor e social alcançados;
Exemplos de progressos específicos e desafios observados;
Reflexão sobre a adaptação das crianças às atividades."
              ></v-textarea>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="pa-md-4">
            <div>
              <label> 5. Participação Familiar</label>
              <v-textarea
                rows="3"
                autofocus
                v-model="form.participacao_familiar"
                placeholder="Descreva as estratégias adotadas para envolver as famílias nas ações pedagógicas e os resultados obtidos."
              ></v-textarea>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="pa-md-4">
            <div>
              <label>6. Considerações Finais e Recomendações</label>
              <v-textarea
                rows="3"
                autofocus
                v-model="form.consideracoes_finais_recomendacoes"
                placeholder="Apresente uma síntese das ações, ressaltando as conquistas e as necessidades de aprimoramento. Liste
recomendações para o próximo ano letivo, com base nos pontos observados."
              ></v-textarea>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-md-4">
            <div>
              <label>
                7. Anexos (upload de imagens) <br />
                Inclua fotos, desenhos das crianças, feedback das famílias que ilustrem o trabalho
                realizado. 4 (Quatro imagens de cada período letivo será o suficiente).
              </label>
            </div>
            <v-row class="mt-4" justify="space-between">
              <v-col cols="12" md="3">
                <h5>Imagens do 1º Período:</h5>

                <v-file-input
                  accept="image/jpeg, image/png"
                  multiple
                  counter
                  chips
                  outlined
                  placeholder="Selecione imagens"
                  @change="(event) => handleImagensSelecionadas('imagens_primeiro_periodo', event)"
                ></v-file-input>

                <div v-if="form.imagens_primeiro_periodo.length > 0">
                  <div
                    v-for="(imagem, index) in form.imagens_primeiro_periodo"
                    :key="index"
                    class="d-flex align-center mb-2"
                  >
                    <v-icon class="mr-2">mdi-image</v-icon>

                    <span class="flex-grow-1">
                      {{
                        imagem.name
                          ? imagem.name
                          : typeof imagem === "string"
                          ? JSON.parse(imagem).nome_original
                          : imagem.nome_original || obterNomeArquivo(imagem.url || imagem)
                      }}
                    </span>

                    <v-btn
                      @click="removerImagem(index, 'imagens_primeiro_periodo')"
                      color="red"
                      small
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>

                <!-- Indicador visual de aceitação das imagens -->
              </v-col>

              <v-col cols="12" md="3">
                <h5>Imagens do 2º Período:</h5>
                <v-file-input
                  accept="image/jpeg, image/png"
                  multiple
                  counter
                  chips
                  outlined
                  placeholder="Selecione imagens"
                  @change="(event) => handleImagensSelecionadas('imagens_segundo_periodo', event)"
                >
                </v-file-input>
                <div v-if="form.imagens_segundo_periodo.length > 0">
                  <div
                    v-for="(imagem, index) in form.imagens_segundo_periodo"
                    :key="index"
                    class="d-flex align-center mb-2"
                  >
                    <v-icon class="mr-2">mdi-image</v-icon>
                    <span class="flex-grow-1">
                      {{
                        imagem.name
                          ? imagem.name
                          : typeof imagem === "string"
                          ? JSON.parse(imagem).nome_original
                          : imagem.nome_original || obterNomeArquivo(imagem.url || imagem)
                      }}

                      <!-- Se for um File, exibe o nome. Se for um objeto salvo, exibe nome_original. Se for string, extrai da URL. -->
                    </span>
                    <v-btn
                      @click="removerImagem(index, 'imagens_segundo_periodo')"
                      color="red"
                      small
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <h5>Imagens do 3º Período:</h5>
                <v-file-input
                  accept="image/jpeg, image/png"
                  multiple
                  counter
                  chips
                  outlined
                  placeholder="Selecione imagens"
                  @change="(event) => handleImagensSelecionadas('imagens_terceiro_periodo', event)"
                >
                </v-file-input>

                <div v-if="form.imagens_terceiro_periodo.length > 0">
                  <div
                    v-for="(imagem, index) in form.imagens_terceiro_periodo"
                    :key="index"
                    class="d-flex align-center mb-2"
                  >
                    <v-icon class="mr-2">mdi-image</v-icon>

                    <span class="flex-grow-1">
                      {{
                        imagem.name
                          ? imagem.name
                          : typeof imagem === "string"
                          ? JSON.parse(imagem).nome_original
                          : imagem.nome_original || obterNomeArquivo(imagem.url || imagem)
                      }}

                      <!-- Se for um File, exibe o nome. Se for um objeto salvo, exibe nome_original. Se for string, extrai da URL. -->
                    </span>

                    <v-btn
                      @click="removerImagem(index, 'imagens_terceiro_periodo')"
                      color="red"
                      small
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <h5>Imagens do 4º Período:</h5>
                <v-file-input
                  accept="image/jpeg, image/png"
                  multiple
                  counter
                  chips
                  outlined
                  placeholder="Selecione imagens"
                  @change="(event) => handleImagensSelecionadas('imagens_quarto_periodo', event)"
                >
                </v-file-input>

                <div v-if="form.imagens_quarto_periodo.length > 0">
                  <div
                    v-for="(imagem, index) in form.imagens_quarto_periodo"
                    :key="index"
                    class="d-flex align-center mb-2"
                  >
                    <v-icon class="mr-2">mdi-image</v-icon>

                    <span class="flex-grow-1">
                      {{
                        imagem.name
                          ? imagem.name
                          : typeof imagem === "string"
                          ? JSON.parse(imagem).nome_original
                          : imagem.nome_original || obterNomeArquivo(imagem.url || imagem)
                      }}

                      <!-- Se for um File, exibe o nome. Se for um objeto salvo, exibe nome_original. Se for string, extrai da URL. -->
                    </span>

                    <v-btn
                      @click="removerImagem(index, 'imagens_quarto_periodo')"
                      color="red"
                      small
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="pa-md-4">
            <div>
              <label
                >8. Desenvolvimento Estudantil sob a análise do trabalho a partir dos Campos de
                Experiências:</label
              >
            </div>
            <v-card-actions>
              <v-btn class="caption ml-2" @click="show = !show" text
                >Legendas para auxiliar no lançamento do sistema BNCC</v-btn
              >

              <v-spacer></v-spacer>

              <v-btn icon @click="show = !show">
                <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>

                <v-card-text>
                  <v-chip v-for="(legenda, i) in legendas" :key="i" class="ma-2" small>
                    <strong>{{ i }}</strong
                    >&nbsp;-&nbsp;{{ legenda }}
                  </v-chip>
                  <v-divider></v-divider>
                  <v-chip
                    v-for="(style, i) in styleClass"
                    :key="i"
                    class="ma-2"
                    small
                    :color="style"
                  >
                    <strong>{{ i }}</strong
                    >&nbsp;-&nbsp;lançamento(s)
                  </v-chip>
                </v-card-text>
              </div>
            </v-expand-transition>
            <v-card-text>
              <v-simple-table class="elevation-1">
                <template v-slot:default>
                  <template v-if="filteredSistemaBNCC.length">
                    <tbody v-for="parent in filteredSistemaBNCC" :key="parent.id">
                      <tr>
                        <td colspan="5" class="text-h6">{{ parent.descricao }}</td>
                      </tr>
                      <tr
                        v-for="child in parent.children"
                        :key="child.id"
                        :class="changeColor(child)"
                      >
                        <td>
                          <span class="font-weight-medium">
                            {{ child.apelido }}
                          </span>
                          - {{ child.descricao }}
                        </td>
                        <td>
                          <v-select
                            class="mt-4"
                            :items="opcoes"
                            v-model="notas[child.id].nota_p1"
                            label="1° PERIODO"
                            outlined
                            dense
                          ></v-select>
                        </td>
                        <td>
                          <v-select
                            class="mt-4"
                            :items="opcoes"
                            v-model="notas[child.id].nota_p2"
                            label="2° PERIODO"
                            outlined
                            dense
                          ></v-select>
                        </td>
                        <td>
                          <v-select
                            class="mt-4"
                            :items="opcoes"
                            v-model="notas[child.id].nota_p3"
                            label="3° PERIODO"
                            outlined
                            dense
                          ></v-select>
                        </td>
                        <td>
                          <v-select
                            class="mt-4"
                            :items="opcoes"
                            v-model="notas[child.id].nota_p4"
                            label="4° PERIODO"
                            outlined
                            dense
                          ></v-select>
                        </td>
                      </tr>
                    </tbody>
                  </template>

                  <template v-else>
                    <tbody>
                      <tr>
                        <td colspan="5" class="text-center text-h6">
                          Nenhum Campos de Experiências disponível para exibição.
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="pa-md-4">
            <div>
              <label
                >9. Gráfico do Desenvolvimento Estudantil sob a Análise do trabalho a partir dos
                Campos de Experiências:</label
              >
            </div>
            <v-card class="pa-md-4">
              <div>
                <e-grafico-bncc
                  ref="graficoBNCC"
                  v-if="filteredSistemaBNCC.length > 0"
                  :filteredSistemaBNCC="filteredSistemaBNCC"
                  :notas="notas"
                />
                <p v-else>Carregando gráficos...</p>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn color="primary" :loading="submittingForm" type="submit"> Enviar Dados </v-btn>
        </v-col>
      </v-row>
    </form>

    <v-dialog v-model="termoDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline">Termo de Autorização</v-card-title>
        <v-card-text>
          <p>
            Todos os estudantes que aparecem nas imagens possuem os Termos de Autorização para Uso
            de Imagem devidamente assinados por seus responsáveis legais, em seus Dossiês.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="recusarTermo">Recusar</v-btn>
          <v-btn color="green darken-1" text @click="aceitarTermo">Aceitar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="mostrarAlerta" max-width="400">
      <v-card>
        <v-card-title class="text-h5">Arquivo Inválido</v-card-title>
        <v-card-text>
          Por favor, selecione apenas arquivos do tipo <strong>JPEG</strong> ou
          <strong>PNG</strong>.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fecharAlerta">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      aluno: null,
      matricula: null,
      turma: null,
      gestao_de_aulas: null,
      exibirCampos: false,
      periodos: [1, 2, 3, 4],
      submittingForm: false,
      termoDialog: false,
      mostrarAlerta: false,
      imagensSelecionadas: null,
      periodoSelecionado: null,
      termosAceitos: {},
      notas: {},
      form: {
        matricula_id: null,
        introducao: null,
        objetivos: null,
        desenvolvimento_motor: null,
        desenvolvimento_cognitivo: null,
        desenvolvimento_social: null,
        linguagem_comunicacao: null,
        atividades_artisticas: null,
        avaliacao_resultados: null,
        participacao_familiar: null,
        consideracoes_finais_recomendacoes: null,
        imagens_primeiro_periodo: [],
        imagens_segundo_periodo: [],
        imagens_terceiro_periodo: [],
        imagens_quarto_periodo: [],
        nota_p1: null,
      },
      legendas: null,
      styleClass: [
        "red lighten-4",
        "yellow accent-1",
        "orange accent-1",
        "lime lighten-3",
        "light-green lighten-1",
      ],
      show: false,
      sistemaBNCC: null,
      opcoes: null,
      presencasCount: null,
    };
  },
  computed: {
    filteredSistemaBNCC() {
      if (!this.sistemaBNCC || !this.avaliacoesBnccExibidas) {
        return [];
      }

      return this.sistemaBNCC
        .map((parent) => {
          const filteredChildren = parent.children.filter((child) =>
            this.avaliacoesBnccExibidas.some(
              (avaliacao) =>
                avaliacao.sistema_bncc_id === child.id &&
                avaliacao.exibir_no_relatorio_anual_infantil === 1
            )
          );

          return filteredChildren.length > 0 ? { ...parent, children: filteredChildren } : null;
        })
        .filter(Boolean);
    },
  },

  created() {
    this.loadBasicData();
  },

  methods: {
    changeColor(obj) {
      let countClass = 0;
      if (this.notas[obj.id].nota_p1) {
        countClass += 1;
      }
      if (this.notas[obj.id].nota_p2) {
        countClass += 1;
      }
      if (this.notas[obj.id].nota_p3) {
        countClass += 1;
      }
      if (this.notas[obj.id].nota_p4) {
        countClass += 1;
      }
      return this.styleClass[countClass];
    },
    obterNomeArquivo(caminho) {
      return caminho ? caminho.split("/").pop() : "";
    },
    abrirTermoDialog(periodo) {
      this.periodoAtual = periodo;
      this.termoDialog = true;
    },

    aceitarTermo() {
      if (this.periodoAtual) {
        this.termosAceitos[this.periodoAtual] = true;
        this.form[`termo_aceito_${this.periodoAtual}`] = true;

        if (!this.form[this.periodoAtual]) {
          this.form[this.periodoAtual] = [];
        }

        this.form[this.periodoAtual] = [
          ...this.form[this.periodoAtual],
          ...this.imagensTemporarias,
        ];

        this.imagensTemporarias = [];
        this.termoDialog = false;
      }
    },

    recusarTermo() {
      if (this.periodoAtual) {
        this.termosAceitos[this.periodoAtual] = false;
        this.form[`termo_aceito_${this.periodoAtual}`] = false;

        // Se recusado, não adiciona as imagens
        this.imagensTemporarias = [];
        this.termoDialog = false;
      }
    },

    handleImagensSelecionadas(periodo, event) {
      const novasImagens = event?.target?.files || event;
      if (!novasImagens) return;

      const imagensValidas = Array.from(novasImagens).filter((file) => {
        const tipoValido = file.type === "image/jpeg" || file.type === "image/png";
        if (!tipoValido) {
          this.mostrarAlerta = true;
        }
        return tipoValido;
      });

      if (imagensValidas.length === 0) return;

      this.imagensTemporarias = imagensValidas;
      this.periodoAtual = periodo;
      this.termoDialog = true; // Abre o modal antes de adicionar as imagens
    },

    fecharAlerta() {
      this.mostrarAlerta = false;
    },

    removerImagem(index, periodo) {
      this.form[periodo].splice(index, 1);
    },

    toggleCampos() {
      this.exibirCampos = !this.exibirCampos;
    },
    async loadBasicData() {
      try {
        this.$loaderService.open("Carregando dados...");
        const payload = await this.$services.relatoriosService.syncAllRelatorioAnualInfantil(
          parseInt(this.$route.params.matricula_id, 10)
        );

        this.aluno = payload?.matricula?.aluno || {};
        this.matricula = payload?.matricula || {};
        this.turma = payload?.matricula?.turma || {};
        this.gestao_de_aulas = payload?.matricula?.turma?.gestao_de_aulas || {};
        this.avaliacoesBnccExibidas = payload.formBNCC?.avaliacoesBnccExibidas;
        this.legendas = payload.formBNCC?.sistema?.legenda;
        this.sistemaBNCC = payload.formBNCC?.sistema?.children;
        this.opcoes = Object.keys(payload.formBNCC?.sistema.opcoes);
        this.notas = payload.notas || {};
        this.presencasCount = payload.presencasCount;

        this.filteredSistemaBNCC.forEach((parent) => {
          parent.children.forEach((child) => {
            if (!this.notas[child.id]) {
              this.$set(this.notas, child.id, {
                nota_p1: "",
                nota_p2: "",
                nota_p3: "",
                nota_p4: "",
              });
            }
          });
        });

        const safeJsonParse = (str) => {
          if (!str) return [];
          try {
            const parsed = JSON.parse(str);
            return Array.isArray(parsed) ? parsed : [];
          } catch (e) {
            return [];
          }
        };

        this.form = {
          matricula_id: payload.form?.matricula_id || "",
          introducao: payload.form?.introducao || "",
          objetivos: payload.form?.objetivos || "",
          desenvolvimento_motor: payload.form?.desenvolvimento_motor || "",
          desenvolvimento_cognitivo: payload.form?.desenvolvimento_cognitivo || "",
          desenvolvimento_social: payload.form?.desenvolvimento_social || "",
          linguagem_comunicacao: payload.form?.linguagem_comunicacao || "",
          atividades_artisticas: payload.form?.atividades_artisticas || "",
          avaliacao_resultados: payload.form?.avaliacao_resultados || "",
          participacao_familiar: payload.form?.participacao_familiar || "",
          consideracoes_finais_recomendacoes:
            payload.form?.consideracoes_finais_recomendacoes || "",
          termo_aceito_primeiro_periodo: payload.form?.termo_aceito_primeiro_periodo || "",
          termo_aceito_segundo_periodo: payload.form?.termo_aceito_segundo_periodo || "",
          termo_aceito_terceiro_periodo: payload.form?.termo_aceito_terceiro_periodo || "",
          imagens_primeiro_periodo: safeJsonParse(payload.form?.imagens_primeiro_periodo),
          imagens_segundo_periodo: safeJsonParse(payload.form?.imagens_segundo_periodo),
          imagens_terceiro_periodo: safeJsonParse(payload.form?.imagens_terceiro_periodo),
          imagens_quarto_periodo: safeJsonParse(payload.form?.imagens_quarto_periodo),
        };
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },

    async createOrUpdate() {
      this.submittingForm = true;
      try {
        const formData = new FormData();
        const campos = [
          "introducao",
          "objetivos",
          "desenvolvimento_motor",
          "desenvolvimento_cognitivo",
          "desenvolvimento_social",
          "linguagem_comunicacao",
          "atividades_artisticas",
          "avaliacao_resultados",
          "participacao_familiar",
          "consideracoes_finais_recomendacoes",
        ];

        campos.forEach((campo) => {
          formData.append(campo, this.form[campo] || "");
        });

        Object.keys(this.notas).forEach((sistemaBnccId) => {
          const nota = this.notas[sistemaBnccId];
          formData.append(`notas[${sistemaBnccId}][nota_p1]`, nota.nota_p1 || "");
          formData.append(`notas[${sistemaBnccId}][nota_p2]`, nota.nota_p2 || "");
          formData.append(`notas[${sistemaBnccId}][nota_p3]`, nota.nota_p3 || "");
          formData.append(`notas[${sistemaBnccId}][nota_p4]`, nota.nota_p4 || "");
        });

        const periodos = [
          "primeiro_periodo",
          "segundo_periodo",
          "terceiro_periodo",
          "quarto_periodo",
        ];

        const MAX_IMAGENS = 4;

        for (const periodo of periodos) {
          const imagens = this.form[`imagens_${periodo}`] || [];

          if (imagens.length > MAX_IMAGENS) {
            this.$toast.danger(`Você só pode enviar até ${MAX_IMAGENS} imagens para o ${periodo}.`);
            return;
          }

          imagens.forEach((imagem) => {
            if (typeof imagem === "string") {
              formData.append(`imagens_${periodo}_existente[]`, imagem);
            } else if (imagem?.url) {
              formData.append(
                `imagens_${periodo}_existente[]`,
                JSON.stringify({
                  url: imagem.url,
                  nome_original: imagem.nome_original,
                })
              );
            } else if (imagem instanceof File) {
              formData.append(`imagens_${periodo}[]`, imagem);
            }
          });

          const termoKey = `imagens_${periodo}`; // Ajustando a chave corretamente
          const termoAceito = this.termosAceitos[termoKey] === true ? "1" : "0";
          formData.append(`termo_aceito_${periodo}`, termoAceito);
        }

        if (this.$refs.graficoBNCC) {
          this.$refs.graficoBNCC.filteredSistemaBNCC.forEach((parent, index) => {
            const chartImage = this.$refs.graficoBNCC.getChartImage(index, parent.id);
            if (chartImage) {
              const blob = this.base64ToBlob(chartImage, "image/png");
              formData.append(`grafico_${parent.id}`, blob, `grafico_${parent.id}.png`);
            }
          });
        }

        await this.$services.relatoriosService.salvarRelatorioAnualInfantil(
          parseInt(this.$route.params.matricula_id, 10),
          formData
        );

        // Mensagem de sucesso
        this.$toast.success("Relatório salvo com sucesso!");
        this.$forceUpdate();
        // window.location.reload();
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.submittingForm = false;
      }
    },
    base64ToBlob(base64, mime) {
      const byteString = atob(base64.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mime });
    },
  },
};
</script>

<style></style>
